import { createSlice } from '@reduxjs/toolkit';

const notificationsSlice = createSlice({
  name: 'notificationsBunches',
  initialState: {
    bunches: [],
    counts: 0,
    editBunches: {},
    addUpdateBunchesSuccess: false,
  },
  reducers: {
    fetchNotificationsBunches(state, action) { 
      state.bunches = action.payload;
    },
    notificationsBunchesCount(state, action) { 
      state.counts = action.payload ? action.payload: 0;
    },
    addUpdateNotificationsBunchesSuccess(state, action) { 
      state.addUpdateBunchesSuccess = action.payload;
    },
    fetchEditNotificationsBunches(state, action) { 
      state.editBunches = action.payload;
    },
  },
});

export const { fetchNotificationsBunches, notificationsBunchesCount, addUpdateNotificationsBunchesSuccess, fetchEditNotificationsBunches} = notificationsSlice.actions;

export default notificationsSlice.reducer;