import { createSlice } from '@reduxjs/toolkit';

const productSlice = createSlice({
  name: 'public',
  initialState: {
    meta: {},
    products: [],
    subProducts: [],
    grievances: [],
    contactPage: {},
    disclaimerPage: {},
    privacyPolicyPage: {},
    refundPolicyPage: {},
    termsConditionsPage: {},
    servicePage: {},
    aboutPage: {},
    footerPage: {},
    mainMenus: [],
    banks: [],
    flowBuilder: {},
    flowBuilderAllSteps: [],
    totalSteps: 0,
    icons: [],
    dataOfSteps: {},
    caseData: null,
    validateAPIcallSuccess: false,
    submitInquirySuccess: false,
    caseDataApiCallStatus: false,
    paymentRedirectUrl: null,
    caseDataOnId: {},
    dynamicPageContent: {},
    dynamicPageContentLoadApiCall: false,
  },
  reducers: {
    fetchAllPublicProducts(state, action) { 
      state.products = action.payload;
    },
    submitInquirySuccess(state, action) { 
      state.submitInquirySuccess = action.payload;
    },
    fetchContactPagePublicData(state, action) { 
      state.contactPage = action.payload;
    },
    fetchMainMenusPublicData(state, action) { 
      state.mainMenus = action.payload;
    },
    fetchServicePagePublicData(state, action) { 
      state.servicePage = action.payload;
    },
    fetchFooterPagePublicData(state, action) { 
      state.footerPage = action.payload;
    },
    fetchAboutPagePublicData(state, action) { 
      state.aboutPage = action.payload;
    },
    fetchPrivacyPolicyPagePublicData(state, action) { 
      state.privacyPolicyPage = action.payload;
    },
    fetchRefundPolicyPagePublicData(state, action) { 
      state.refundPolicyPage = action.payload;
    },
    fetchTermsConditionsPagePublicData(state, action) { 
      state.termsConditionsPage = action.payload;
    },
    fetchDisclaimerPagePublicData(state, action) { 
      state.disclaimerPage = action.payload;
    },
    fetchMetaPublic(state, action) { 
      state.meta = action.payload;
    },
    fetchStepsFromBackend(state, action) { 
      state.dataOfSteps = action.payload;
    },
    fetchCaseDataOnId(state, action) { 
      state.caseDataOnId = action.payload;
    },
    fetchBanks(state, action) { 
      state.banks = action.payload;
    },
    fetchFlowBuilderSteps(state, action) { 
      state.flowBuilder = action.payload;
    },
    fetchFlowBuilderAllSteps(state, action) { 
      state.flowBuilderAllSteps = action.payload;
    },
    fetchAllPublicSubProducts(state, action) { 
      state.subProducts = action.payload;
    },
    fetchAllPublicGrievance(state, action) { 
      state.grievances = action.payload;
    },
    fetchPublicStepsCount(state, action) { 
      state.totalSteps = action.payload;
    },
    fetchIconsOfPublicProducts(state, action) { 
      state.icons = action.payload;
    },
    fetchCaseDataOnSubmit(state, action) { 
      state.caseData = action.payload;
    },
    validateAPIcallSuccessOnSubmit(state, action) { 
      state.validateAPIcallSuccess = action.payload;
    },
    caseDataApiCallStatusAction(state, action) { 
      state.caseDataApiCallStatus = action.payload;
    },
    paymentRedirectUrl(state, action) { 
      state.paymentRedirectUrl = action.payload;
    },
    dynamicPagePublicContent(state, action) { 
      state.dynamicPageContent = action.payload;
    },
    dynamicPagePublicContentLoadApiCall(state, action) { 
      state.dynamicPageContentLoadApiCall = action.payload;
    },
  },
});

export const {submitInquirySuccess, dynamicPagePublicContent,
  fetchPrivacyPolicyPagePublicData, dynamicPagePublicContentLoadApiCall,
  fetchTermsConditionsPagePublicData, fetchMainMenusPublicData,
  fetchDisclaimerPagePublicData, fetchRefundPolicyPagePublicData,
  fetchServicePagePublicData, fetchAboutPagePublicData, fetchFooterPagePublicData,
  fetchMetaPublic, fetchContactPagePublicData, fetchCaseDataOnId, paymentRedirectUrl, caseDataApiCallStatusAction, validateAPIcallSuccessOnSubmit ,fetchCaseDataOnSubmit, fetchAllPublicProducts, fetchStepsFromBackend, fetchBanks, fetchFlowBuilderAllSteps, fetchPublicStepsCount, fetchFlowBuilderSteps, fetchIconsOfPublicProducts, fetchAllPublicSubProducts, fetchAllPublicGrievance} = productSlice.actions;

export default productSlice.reducer;