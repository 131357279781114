import AppLang from 'AppLang';
import Snackbar from 'ui-component/extended/Snackbar';
import NavigationScroll from 'layout/NavigationScroll';
import ThemeCustomization from 'themes';
import Locales from 'ui-component/Locales';
import RTLLayout from 'ui-component/RTLLayout';
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import ThemeRoutes from 'routes';

const App = () => {
    const location = window.location.host;
    const pathArr = location.replace(/^\/|\/$/g, '').split('.').slice();

    return (<>
        {(pathArr.includes('app') || pathArr.includes('partner')) ?
            <ThemeCustomization>
                <RTLLayout>
                    <Locales>
                        <NavigationScroll>
                            <AuthProvider>
                                <>
                                    <ThemeRoutes />
                                    <Snackbar />
                                </>
                            </AuthProvider>
                        </NavigationScroll>
                    </Locales>
                </RTLLayout>
            </ThemeCustomization> : <AppLang />}
    </>
    );
};

export default App;
