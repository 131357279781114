import { createSlice } from '@reduxjs/toolkit';

const partnerPayoutSlice = createSlice({
  name: 'partnerPayout',
  initialState: {
    payouts: [],
    payoutCounts: 0,
    editPayout: null,
    addUpdatePayoutSuccess: false,
  },
  reducers: {
    fetchPartnerPayoutList(state, action) {
      state.payouts = action.payload;
    },
    fetchPartnerPayoutCount(state, action) {
      state.payoutCounts = action.payload;
    },
    fetchPartnerEditPayout(state, action) {
      state.editPayout = action.payload;
    },
    addUpdatePartnerPayoutSuccess(state, action) {
      state.addUpdatePayoutSuccess = action.payload;
    },
  },
});

export const { fetchPartnerPayoutList, fetchPartnerPayoutCount, fetchPartnerEditPayout, addUpdatePartnerPayoutSuccess } = partnerPayoutSlice.actions;

export default partnerPayoutSlice.reducer;