//@ts-ignore
import { openSnackbar } from "store/state/data-slices/snackbar";
import {
  addUpdateProfileSuccessAction, verifyGstStatus, verifyPanStatus, verifyBankStatus,
  verifyCINStatus, fetchUserProfileAction, verifyAadhaarStatus, userProfileImageUploadAction, userProfileImageActions,
  fetchUserProfileAgreementAction,
  fetchUserLoginSessionAction
} from "../../data-slices/admin/profileSlice";
import axios from 'utils/axios';

export function fetchUserProfileApi(body?: { agreement?: boolean, beneficiary?: boolean, amountCalc?: boolean }) {
  return async (dispatch: any) => {
    try {
      let url = `${body?.beneficiary ? `/users/profile/fetch?beneficiary=true` : '/users/profile/fetch'}${body?.amountCalc ? '?amountCalc=true' : ''}`;
      const response = await axios({
        url,
        method: 'get',
        data: {}
      });
      if (response.data.success) {
        if (body?.agreement) {
          dispatch(fetchUserProfileAgreementAction(response.data.user));
        } else {
          dispatch(fetchUserProfileAction(response.data.user));
          dispatch(fetchUserProfileAgreementAction(response?.data?.user));
        }
      } else {
        dispatch(fetchUserProfileAction({}));
        dispatch(fetchUserProfileAgreementAction({}));
      }
    } catch (error) {
      dispatch(fetchUserProfileAction({}));
      dispatch(fetchUserProfileAgreementAction({}));
    }
  };
}

export function fetchUserLoginSessionApi() {
  return async (dispatch: any) => {
    try {
      let url = `/users/sessions`;
      const response = await axios({
        url,
        method: 'get',
        data: {}
      });
      if (response?.data?.success) {
        dispatch(fetchUserLoginSessionAction(response?.data?.sessions));       
      } else {
        dispatch(fetchUserLoginSessionAction([]));
      }
    } catch (error) {
      dispatch(fetchUserLoginSessionAction([]));
    }
  };
}

export function fetchUserSessionUpdateLastActiveTimeApi() {
  return async (dispatch: any) => {
    try {
      let url = `/users/sessions`;
      const response = await axios({
        url,
        method: 'post',
        data: {}
      });
      if (response?.data?.success) {
        dispatch(fetchUserLoginSessionApi());       
      }
    } catch (error) {
    }
  };
}

export function updateProfileApi(body: any) {
  return async (dispatch: any) => {
    try {
      const response = await axios({
        url: `/users/profile/update`,
        method: 'put',
        data: body

      });
      if (response.data.success) {
        dispatch(addUpdateProfileSuccessAction(response.data.success));
        // if (body?.userType === 'request') {
        //   dispatch(fetchChannelOnboardingUserApi());
        // } else {
        //   dispatch(fetchUserProfileApi(body?.beneficiary ? {beneficiary: true} : {}));
        // }
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: response.data.message,
            variant: 'alert',
            alert: {
              color: 'success'
            }
          }));
      } else {
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: response.data.message,
            variant: 'alert',
            alert: {
              color: 'error'
            }
          }));
      }
    } catch (error: any) {
      dispatch(
        openSnackbar({
          open: true,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          message: error.message,
          variant: 'alert',
          alert: {
            color: 'error'
          }
        }));
    }
  };
}

export function verifyProfileDataApi(body: any) {
  return async (dispatch: any) => {
    try {
      const response = await axios({
        url: `/verify`,
        method: 'post',
        data: body

      });
      if (response.data) {
        if (body && body.type === 'gst') dispatch(verifyGstStatus(response.data));
        if (body && body.type === 'pan') dispatch(verifyPanStatus(response.data));
        if (body && body.type === 'bank') dispatch(verifyBankStatus(response.data));
        if (body && body.type === 'cin') dispatch(verifyCINStatus(response.data));
        if (body && (body.type === 'aadhaar' || body.type === 'udyog')) dispatch(verifyAadhaarStatus(response.data));
        if (body && body.type === 'verify') dispatch(verifyAadhaarStatus(response.data));
      }
    } catch (error: any) {
      if (body && body.type === 'gst') dispatch(verifyGstStatus(error));
      if (body && body.type === 'pan') dispatch(verifyPanStatus(error));
      if (body && body.type === 'bank') dispatch(verifyBankStatus(error));
      if (body && body.type === 'cin') dispatch(verifyCINStatus(error));
      if (body && (body.type === 'aadhaar' || body.type === 'udyog')) dispatch(verifyAadhaarStatus(error));
      if (body && body.type === 'verify') dispatch(verifyAadhaarStatus(error));
    }
  };
}

export function updateProfileImageApi(body: any) {
  return async (dispatch: any) => {
    try {
      const response = await axios({
        url: `/upload`,
        method: 'post',
        data: body

      });
      if (response.data.success) {
        dispatch(fetchUserProfileApi());
      }
      dispatch(
        openSnackbar({
          open: true,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: response.data.success ? 'success' : 'error'
          }
        }));
      dispatch(userProfileImageUploadAction(true));
    } catch (error: any) {
      dispatch(userProfileImageUploadAction(true));
      dispatch(
        openSnackbar({
          open: true,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          message: error.message,
          variant: 'alert',
          alert: {
            color: 'error'
          }
        }));
    }
  };
}

export function getProfileImageApi(body: any) {
  return async (dispatch: any) => {
    try {
      const response = await axios({
        url: `/profile/image/profile/${body.id}`,
        method: 'get',
        data: body
      });
      if (response.data) {
        dispatch(userProfileImageActions(response.data));
      } else {
        dispatch(userProfileImageActions(null));
      }
    } catch (error: any) {
      dispatch(userProfileImageActions(null));
    }
  };
}


