import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const DashboardAnalytics = Loadable(lazy(() => import('views/dashboard/Analytics')));
const PartnersCases = Loadable(lazy(() => import('views/admin/partners')));
const Profile = Loadable(lazy(() => import('views/admin/users/profile')));
const MisPayoutViewById = Loadable(lazy(() => import('views/admin/payoutChecker/ViewPayout')));
const MisPayoutView = Loadable(lazy(() => import('views/admin/payoutChecker/ChannelTransactionsPayout')));

// ==============================|| MAIN ROUTING ||============================== //

const PartnerRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard',
            element: <DashboardDefault />,           
        },
        {
            path: '/dashboard/analytics',
            element: <DashboardAnalytics />
        },
        {
            path: '/user/profile',
            element: <Profile />
        },
        {
            path: '/cases-list',
            element: <PartnersCases/>
        },
        {
            path: '/payout-manager/view/:id',
            element: <MisPayoutViewById />
        },
        {
            path: '/payout-manager/view',
            element: <MisPayoutView />
        },
    ],

};

export default PartnerRoutes;
