import { createSlice } from '@reduxjs/toolkit';

const dynamicPagesSlice = createSlice({
  name: 'pages',
  initialState: {
    pages: [],
    editPage: null,
    pagesCounts: null,
    addUpdateSuccess: false
  },
  reducers: {
    fetchDynamicPages(state, action) { 
      state.pages = action.payload;
    },
    fetchDynamicPagesCounts(state, action) { 
      state.pagesCounts = action.payload;
    },
    fetchEditDynamicPages(state, action) { 
      state.editPage = action.payload;
    },
    fetchAddEditSuccessDynamicPages(state, action) { 
      state.addUpdateSuccess = action.payload;
    }
  },
});

export const {fetchAddEditSuccessDynamicPages, fetchDynamicPages, 
  fetchDynamicPagesCounts, fetchEditDynamicPages} = dynamicPagesSlice.actions;

export default dynamicPagesSlice.reducer;