
import { NavItemType } from 'types';

// assets
import {
    IconApps, IconUserCheck, IconBasket, IconMessages, IconLayoutKanban, IconMail, IconCalendar, IconNfc,
    IconBuildingCommunity, IconBuildingBank, IconClipboardList,
    IconBook, IconGizmo, IconUserPlus, IconUsers, IconLanguage, IconClipboard, IconCategory, IconCategory2,
    IconCategoryMinus
} from '@tabler/icons-react';

// constant
const icons = {
    IconApps,
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconBuildingCommunity,
    IconBuildingBank,
    IconClipboardList,
    IconBook,
    IconCategory, IconCategory2,
    IconGizmo,
    IconUserPlus, IconCategoryMinus,
    IconUsers,
    IconLanguage, IconClipboard
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const application: NavItemType = {
    id: 'admin',
    title: 'Admin',
    icon: icons.IconApps,
    type: 'group',
    children: [
        {
            id: 'pages',
            title: 'Pages',
            type: 'collapse',
            selected: ['edit-page', 'pages-list'],
            icon: icons.IconClipboard,
            children: [
                {
                    id: 'menus',
                    title: 'Main Menus',
                    type: 'item',
                    url: '/pages/static/menus'
                },
                {
                    id: 'dynamic',
                    title: 'Dynamic Pages',
                    type: 'collapse',
                    selected: ['edit-page', 'pages-list'],
                    children: [
                        {
                            id: 'create-page',
                            title: 'Create Page',
                            type: 'item',
                            url: '/dynamic/pages/create-page'
                        },
                        {
                            id: 'pages-list',
                            title: 'Pages List',
                            type: 'item',
                            url: '/dynamic/pages/pages-list'
                        },
                    ]
                },
                {
                    id: 'home',
                    title: 'Home Page',
                    type: 'item',
                    url: '/pages/static/home'
                },
                {
                    id: 'contact',
                    title: 'Contact Page',
                    type: 'collapse',
                    selected: ['contact-page', 'contact-page-settings'],
                    children: [
                        {
                            id: 'contact-page',
                            title: 'Translations',
                            type: 'item',
                            url: '/pages/static/contact-page'
                        },
                        {
                            id: 'contact-page-settings',
                            title: 'Settings',
                            type: 'item',
                            url: '/pages/static/contact-page-settings'
                        },
                        {
                            id: 'contact-page-inquiry',
                            title: 'Inquiry',
                            type: 'item',
                            url: '/pages/static/contact-page-inquiry'
                        },
                    ]
                },
                {
                    id: 'about',
                    title: 'About Page',
                    type: 'collapse',
                    selected: ['about', 'about-page-settings'],
                    children: [
                        {
                            id: 'about-page-settings',
                            title: 'Settings',
                            type: 'item',
                            url: '/pages/static/about-page-settings'
                        },
                    ]
                },
                {
                    id: 'services',
                    title: 'Services Page',
                    type: 'collapse',
                    selected: ['services', 'services-page-settings'],
                    children: [
                        {
                            id: 'service-page-settings',
                            title: 'Settings',
                            type: 'item',
                            url: '/pages/static/service-page-settings'
                        },
                    ]
                },
                {
                    id: 'privacy-policy',
                    title: 'Privacy Policy',
                    type: 'item',
                    url: '/pages/static/privacy-policy'
                },
                {
                    id: 'disclaimer',
                    title: 'Disclaimer',
                    type: 'item',
                    url: '/pages/static/disclaimer'
                },
                {
                    id: 'refund-policy',
                    title: 'Refund Policy',
                    type: 'item',
                    url: '/pages/static/refund-policy'
                },
                {
                    id: 'terms-conditions',
                    title: 'Terms Conditions',
                    type: 'item',
                    url: '/pages/static/terms-conditions'
                },
                {
                    id: 'footer',
                    title: 'Footer Sections',
                    type: 'collapse',
                    selected: ['footer', 'footer-page-settings'],
                    children: [
                        {
                            id: 'footer-page-settings',
                            title: 'Settings',
                            type: 'item',
                            url: '/pages/static/footer-page-settings'
                        },
                    ]
                },
                {
                    id: 'case',
                    title: 'Case Page',
                    type: 'item',
                    url: '/pages/static/case'
                },
                {
                    id: 'images',
                    title: 'Pages Images',
                    type: 'item',
                    url: '/pages/static/images'
                },
            ]
        },
        {
            id: 'bank',
            title: 'Bank',
            type: 'collapse',
            icon: icons.IconBuildingBank,
            selected: ['edit-bank', 'add-bank', 'list-bank'],
            children: [
                {
                    id: 'add-bank',
                    title: 'Add Bank',
                    type: 'item',
                    url: '/bank/add-bank'
                },
                {
                    id: 'list-bank',
                    title: 'Bank List',
                    type: 'item',
                    url: '/bank/list-bank'

                },

            ]
        },
        {
            id: 'keywords',
            title: 'Keywords',
            type: 'collapse',
            icon: icons.IconBook,
            selected: ['keywords', 'edit-keyword'],
            children: [
                {
                    id: 'add-keyword',
                    title: 'Add Keyword',
                    type: 'item',
                    url: '/keywords/add-keyword'
                },
                {
                    id: 'keyword-list',
                    title: 'Keywords List',
                    type: 'item',
                    url: '/keywords/keyword-list'
                },
            ]
        },
        {
            id: 'contact',
            title: 'Contacts',
            type: 'collapse',
            icon: icons.IconBook,
            selected: ['contact', 'create-contact', 'create-contact-bunch', 'edit-contact-bunch', 'contact-setting', 'contact-list'],
            children: [
                {
                    id: 'create-contact-bunch',
                    title: 'Add Contact Bunch',
                    type: 'item',
                    url: '/contact/create-contact-bunch'
                },
                {
                    id: 'contact-list-bunch',
                    title: 'Contact List Bunch',
                    type: 'item',
                    url: '/contact/contact-list-bunch'
                },
                {
                    id: 'contact-setting',
                    title: 'Contact Settings',
                    type: 'item',
                    url: '/contact/contact-setting'
                },
            ]
        },
        {
            id: 'third-party',
            title: 'Hunter Integrations',
            type: 'collapse',
            icon: icons.IconBook,
            selected: [],
            children: [
                {
                    id: 'search-domain',
                    title: 'Search Domains',
                    type: 'item',
                    url: '/third-party/hunter/search-domains'
                },
                {
                    id: 'domain-list',
                    title: 'Domains List',
                    type: 'item',
                    url: '/third-party/hunter/domains-list'
                },

            ]
        },
        {
            id: 'subscriptions',
            title: 'Subscriptions',
            type: 'collapse',
            icon: icons.IconCategory,
            selected: ['edit-subscription', 'edit-promo-codes'],
            children: [
                {
                    id: 'create-subscription',
                    title: 'Add Subscriptions',
                    type: 'item',
                    url: '/payment/create-subscription'
                },
                {
                    id: 'subscriptions',
                    title: 'Subscriptions',
                    type: 'item',
                    url: '/payment/subscriptions'
                },
                {
                    id: 'add-promo-codes',
                    title: 'Add Promo Codes',
                    type: 'item',
                    url: '/payment/add-promo-codes'
                },
                {
                    id: 'promo-codes',
                    title: 'Promo Codes',
                    type: 'item',
                    url: '/payment/promo-codes'
                },
            ]
        },
        {
            id: 'product',
            title: 'Product',
            type: 'collapse',
            icon: icons.IconCategory,
            selected: ['product', 'edit-product'],
            children: [
                {
                    id: 'add-product',
                    title: 'Add Product',
                    type: 'item',
                    url: '/product/add-product'
                },
                {
                    id: 'product-list',
                    title: 'Product List',
                    type: 'item',
                    url: '/product/product-list'
                },
                {
                    id: 'product-setting',
                    title: 'Product Settings',
                    type: 'item',
                    url: '/product/product-setting'
                },
                {
                    id: 'product-icons',
                    title: 'Product Icons',
                    type: 'item',
                    url: '/product/product-icons'
                },
            ]
        },
        {
            id: 'product-type',
            title: 'Product Types',
            type: 'collapse',
            icon: icons.IconCategory2,
            selected: ['product-types', 'edit-product-types'],
            children: [
                {
                    id: 'add-product-types',
                    title: 'Product Types Add',
                    type: 'item',
                    url: '/product-types/add-product-types'

                },
                {
                    id: 'product-types-list',
                    title: 'Product Types List',
                    type: 'item',
                    url: '/product-types/product-types-list'

                },
            ]
        },
        {
            id: 'sub-product',
            title: 'Grievances',
            type: 'collapse',
            icon: icons.IconCategoryMinus,
            selected: ['sub-product', 'edit-sub-product', 'add-sub-product'],
            children: [
                {
                    id: 'add-sub-product',
                    title: 'Add Grievance',
                    type: 'item',
                    url: '/sub-product/add-sub-product'
                },
                {
                    id: 'sub-product-list',
                    title: 'Grievance List',
                    type: 'item',
                    url: '/sub-product/sub-product-list'
                },
            ]
        },
        {
            id: 'flow-builder',
            title: 'Flow Builder',
            type: 'collapse',
            icon: icons.IconCategoryMinus,
            selected: ['flow-builder', 'edit', 'question', 'questions'],
            children: [
                {
                    id: 'add',
                    title: 'Add Flow Builder',
                    type: 'item',
                    url: '/flow-builder/add'
                },
                {
                    id: 'list',
                    title: 'Flow Builder List',
                    type: 'item',
                    url: '/flow-builder/list'
                },
                {
                    id: 'custom-fields',
                    title: 'Custom Fields',
                    type: 'item',
                    url: '/flow-builder/custom-fields'

                },
                {
                    id: 'questions',
                    title: 'Questions List',
                    type: 'item',
                    url: '/flow-builder/questions'
                },

            ]
        },
        {
            id: 'case',
            title: 'Complaint Case',
            type: 'collapse',
            icon: icons.IconBuildingCommunity,
            selected: ['case-view', 'payment-view'],
            children: [
                {
                    id: 'complaint-list',
                    title: 'Complaint List',
                    type: 'item',
                    url: '/complaint/complaint-list'
                },
                {
                    id: 'cases-list',
                    title: 'Cases On Invite',
                    type: 'item',
                    url: '/complaint/cases-list'
                },
            ]
        },
        {
            id: 'transactions',
            title: 'Transactions',
            type: 'collapse',
            icon: icons.IconBuildingCommunity,
            selected: ['transactions-list', 'transactions-view', 'transactions-settings'],
            children: [
                {
                    id: 'transactions-list',
                    title: 'Transactions',
                    type: 'item',
                    url: '/transactions/transactions-list'
                },
                {
                    id: 'transactions-settings',
                    title: 'Transactions Settings',
                    type: 'item',
                    url: '/transactions/transactions-settings'
                },
            ]
        },
        {
            id: 'mailTemplates',
            title: 'Mail Templates',
            type: 'collapse',
            selected: ['mail-templates', 'create-template', 'template-list', 'edit-mail-template'],
            icon: icons.IconBuildingCommunity,
            children: [
                {
                    id: 'create-template',
                    title: 'Create Template',
                    type: 'item',
                    url: '/mail-templates/create'
                },
                {
                    id: 'template-list',
                    title: 'Templates List',
                    type: 'item',
                    url: '/mail-templates/list'

                },
                {
                    id: 'static-content',
                    title: 'Static Content',
                    type: 'item',
                    url: '/mail-templates/static-content'

                },
            ]
        },
        {
            id: 'notifications',
            title: 'Notifications',
            type: 'collapse',
            selected: ['notification', 'create-notification', 'edit-notification', 'edit-notification-template', 'system-notifications', 'notification-settings'],
            icon: icons.IconBuildingCommunity,
            children: [
                {
                    id: 'notification-list',
                    title: 'Notify Template List',
                    type: 'item',
                    url: '/notifications/notification-list'
                },
                {
                    id: 'notification-settings',
                    title: 'Notifications Static',
                    type: 'item',
                    url: '/notifications/notification-settings'
                },
                {
                    id: 'system-notifications',
                    title: 'System Notifications',
                    type: 'item',
                    url: '/notifications/system-notifications'
                },
                {
                    id: 'notification-bunch',
                    title: 'Notification Bunch',
                    type: 'item',
                    url: '/notifications/notification-bunch'
                }
            ]
        },
        {
            id: 'export',
            title: 'Export Translations',
            type: 'collapse',
            icon: icons.IconBuildingBank,
            selected: ['export', 'translations'],
            children: [
                {
                    id: 'export',
                    title: 'Export Data',
                    type: 'item',
                    url: '/export/translations'
                },
                // {
                //     id: 'translate',
                //     title: 'Translate Data',
                //     type: 'item',
                //     url: '/export/translate'
                // },
            ]
        },
        {
            id: 'accounts',
            title: 'Accounts',
            type: 'collapse',
            icon: icons.IconBuildingBank,
            selected: ['credential-list', 'file-viewer', 'edit-invoice', 'invoices-list', 'edit-credential', 'edit-company', 'companies-list'],
            children: [
                {
                    id: 'credentials',
                    title: 'Payments',
                    type: 'collapse',
                    selected: ['edit-credential'],
                    children: [
                        {
                            id: 'add-credential',
                            title: 'Add Payments',
                            type: 'item',
                            url: '/account/credential/add-credential'
                        },
                        {
                            id: 'credential-list',
                            title: 'Payments List',
                            type: 'item',
                            url: '/account/credential/credential-list'
                        },
                    ]
                },
                {
                    id: 'companies',
                    title: 'Companies',
                    type: 'collapse',
                    selected: ['edit-company', 'companies-list'],
                    children: [
                        {
                            id: 'add-company',
                            title: 'Add Company',
                            type: 'item',
                            url: '/account/companies/add-company'
                        },
                        {
                            id: 'companies-list',
                            title: 'Companies List',
                            type: 'item',
                            url: 'account/companies/companies-list'
                        },
                    ]
                },
                {
                    id: 'invoices',
                    title: 'Invoices',
                    type: 'collapse',
                    selected: ['edit-invoice', 'invoices-list', 'file-viewer'],
                    children: [
                        {
                            id: 'add-invoice',
                            title: 'Add Invoice',
                            type: 'item',
                            url: '/account/invoices/add-invoice'
                        },
                        {
                            id: 'invoices-list',
                            title: 'Invoices List',
                            type: 'item',
                            url: 'account/invoices/invoices-list'
                        },
                    ]
                },
                {
                    id: 'category',
                    title: 'Category',
                    type: 'item',
                    url: '/account/category'
                },
            ]
        },
        {
            id: 'partner-manager',
            title: 'Partners',
            type: 'collapse',
            disabled: true,
            icon: icons.IconBuildingBank,
            children: [
                {
                    id: 'payout-list',
                    title: 'Payout List',
                    type: 'item',
                    url: '/partner/payout-list'
                },
            ]
        },
        {
            id: 'api-settings',
            title: 'API Settings',
            type: 'collapse',
            icon: icons.IconBuildingBank,
            selected: ['api-settings', 'google', 'zoop', 'obligr', 'smtp', 'translate'],
            children: [
                {
                    id: 'google',
                    title: 'Google',
                    type: 'collapse',
                    children: [
                        {
                            id: 'smtp',
                            title: 'SMTP',
                            type: 'item',
                            url: '/api-settings/google/smtp'
                        },
                        {
                            id: 'translate',
                            title: 'Translate',
                            type: 'item',
                            url: '/api-settings/google/translate'
                        },
                        {
                            id: 'gmail-oauth',
                            title: 'Gmail OAuth',
                            type: 'item',
                            url: '/api-settings/google/gmail-oauth'
                        },
                    ]
                },
                {
                    id: 'obligr',
                    title: 'Obligr',
                    type: 'item',
                    url: '/api-settings/obligr'
                },
                // {
                //     id: 'zoop',
                //     title: 'Zoop',
                //     type: 'item',
                //     url: '/api-settings/zoop'
                // },
            ]
        },
        {
            id: 'users',
            title: 'Users',
            type: 'collapse',
            selected: ['edit-user', 'edit-role'],
            icon: icons.IconUserCheck,
            children: [
                {
                    id: 'add-user',
                    title: 'Add User',
                    type: 'item',
                    url: '/user/add-user'
                },
                {
                    id: 'users-list',
                    title: 'User List',
                    type: 'item',
                    url: '/user/users-list'
                },
                {
                    id: 'roles-permissions',
                    title: 'Roles & Permissions',
                    type: 'collapse',
                    selected: ['edit-role'],
                    children: [
                        {
                            id: 'add-role',
                            title: 'Add Role',
                            type: 'item',
                            url: '/user/role/add-role'
                        },
                        {
                            id: 'list-role',
                            title: 'Role List',
                            type: 'item',
                            url: '/user/role/roles-list'
                        },
                    ]
                }

            ]
        },
    ]
};

export default application;





