import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Chip,
    ClickAwayListener,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    Link,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';

// third-party
import { FormattedMessage } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import useAuth from 'hooks/useAuth';

// assets
import { IconLogout, IconSettings } from '@tabler/icons';
import useConfig from 'hooks/useConfig';
import { getCurrentUserProfile } from 'store/state/dsaSelector';
import { useDispatch, useSelector } from 'store';
import { userProfileImageSelector } from 'store/state/profileSelector';

import upperCase from 'lodash/upperCase';
import { Rupees } from 'views/components/Rupee';
import axios from 'utils/axios';
import { openSnackbar } from 'store/state/data-slices/snackbar';
import { fetchUserProfileApi } from 'store/state/data-layer/admin/profile';
// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const theme = useTheme();
    const { borderRadius } = useConfig();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const getCurrentUser = useSelector(getCurrentUserProfile);
    const getImageOfProfile = useSelector(userProfileImageSelector);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const { logout } = useAuth();
    const account: any = useAuth();
    const user: any = account?.user;
    const [amount, setAmount] = useState(0);

    const [open, setOpen] = useState(false);
    const [openPayoutDialog, setOpenPayoutDialog] = useState(false);
    const useKycCompleted = user && user.type !== 'admin' && user['isKycCompleted'];
    /**
     * anchorRef is used on different components and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef<any>(null);

    const handleLogout = async (isTrue?: boolean) => {
        try {
            await logout(isTrue ? isTrue : false);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (user && user.type !== 'admin') {
            dispatch(fetchUserProfileApi({ amountCalc: true }));
        }
    }, [user]);


    useEffect(() => {
        if (user && user.type !== 'admin') {
            const { commission, redeemed } = user;
            const am = Number(commission) - Number(redeemed);
            setAmount(am);
        }
    }, [user]);

    const handleCreatePayoutCurrent = async (userId: number) => {
        try {
            const response = await axios({
                url: `/partner-payment/create`,
                method: 'post',
                data: { userId }
            });
            setOpenPayoutDialog(false);
            if (response.data.success) {
                navigate(`/payout-manager/view/${response.data?.data?.id}`);
            }
            dispatch(
                openSnackbar({
                    open: true,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    message: response.data.message,
                    variant: 'alert',
                    alert: {
                        color: response.data.success ? 'success' : 'error'
                    }
                }));
        } catch (error: any) {
            setOpenPayoutDialog(false);
            dispatch(
                openSnackbar({
                    open: true,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    message: error.message,
                    variant: 'alert',
                    alert: {
                        color: error.success ? 'success' : 'error'
                    }
                }));
        }
    };

    const handleListItemClick = (event: React.MouseEvent<HTMLDivElement>, index: number, route: string = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>

            <Chip
                sx={{
                    height: '48px', position: 'relative',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.primary.main}!important`,
                        color: theme.palette.primary.light,
                        '& svg': {
                            stroke: theme.palette.primary.light
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={
                    <Avatar
                        src={`${getImageOfProfile ? getImageOfProfile : account.user && account.user?.profileImage ? account.user?.profileImage : ''}`}
                        alt="user-images"
                        sx={{
                            ...theme.typography.mediumAvatar,
                            margin: '8px 0 8px 8px !important',
                            cursor: 'pointer'
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                    />
                }
                label={<IconSettings stroke={1.5} size="24px" color={theme.palette.primary.main} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
                aria-label="user-account"
            />

            <Popper
                placement="bottom"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                style={{
                    maxWidth: '300px',
                    position: 'absolute',
                    zIndex: 1300,
                    right: '20px',
                    left: 'inherit',
                    top: '70px'

                }}
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 14]
                        }
                    }
                ]}>
                <ClickAwayListener onClickAway={handleClose}>
                    <Paper>
                        {open && (
                            <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                <Box sx={{ p: 2 }}>
                                    <Stack>
                                        <Stack direction="row" spacing={0.5} alignItems="center">
                                            <Typography variant="h4">Hello,</Typography>
                                            <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                                {account?.user?.name}
                                            </Typography>
                                        </Stack>
                                        <Typography variant="subtitle2">{upperCase(getCurrentUser.type)}</Typography>
                                        <Typography variant="subtitle2">{getCurrentUser.email}</Typography>
                                    </Stack>
                                    <Grid container spacing={3}>
                                        <Grid item>
                                            <Chip
                                                sx={{
                                                    my: '10px',
                                                    fontWeight: 'bold',
                                                    bgcolor: useKycCompleted ? theme.palette.success.dark : theme.palette.warning.dark,
                                                    color: '#ffffff !important'
                                                }}
                                                size='small'
                                                icon={
                                                    <Avatar
                                                        sx={{
                                                            ...theme.typography.smallAvatar,
                                                            margin: '1px !important',
                                                            cursor: 'pointer',
                                                            bgcolor: useKycCompleted ? theme.palette.success.dark : theme.palette.warning.dark,
                                                            color: `#ffffff !important`,

                                                        }}
                                                        src={`${getImageOfProfile ? getImageOfProfile : account.user && account.user?.profileImage ? account.user?.profileImage : ''}`}
                                                        alt="user-images"
                                                        color="inherit"
                                                    />
                                                }
                                                label={!useKycCompleted ? "User Kyc Pending!" : 'User Kyc Completed!'}
                                                variant="outlined"
                                                color={useKycCompleted ? "success" : "warning"}
                                                aria-label="user-account"
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Divider />
                                <Box sx={{ p: 2 }}>
                                    <Stack>
                                        <Stack spacing={0.5}>
                                            <Typography variant="h4">Yoo!, Congrats 🎉 </Typography>
                                            <Typography variant="caption">Now you got commission against cases.</Typography>
                                            <Typography variant="caption">Enjoy it!</Typography>
                                            <Typography variant="caption">
                                                {<Button color="secondary" sx={{ mr: '15px' }} variant='outlined' type='button' size="small">
                                                    Amount : {Rupees(amount ? amount : 0).format()} 
                                                </Button>}
                                                {(!!amount && Number(amount) > 0) && <Button color="secondary" variant='contained' type='button' size="small" onClick={(e) => {
                                                    e.preventDefault();
                                                    setOpenPayoutDialog(true);
                                                }}>
                                                    Redeem Amount
                                                </Button>}
                                            </Typography>
                                            {user?.commission && <Typography variant="caption">Total Commission: {user?.commission ? Rupees(user?.commission).format() : 0} </Typography>}
                                        </Stack>
                                    </Stack>
                                </Box>
                                <Divider />
                                <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                                    <Box sx={{ p: 2, pt: 0 }}>
                                        <List
                                            component="nav"
                                            sx={{
                                                width: '100%',
                                                maxWidth: 350,
                                                minWidth: 300,
                                                backgroundColor: theme.palette.background.paper,
                                                borderRadius: '10px',
                                                [theme.breakpoints.down('md')]: {
                                                    minWidth: '100%'
                                                },
                                                '& .MuiListItemButton-root': {
                                                    mt: 0.5
                                                }
                                            }}
                                        >
                                            <ListItemButton
                                                sx={{ borderRadius: `${borderRadius}px` }}
                                                selected={selectedIndex === 0}
                                                onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                                                    handleListItemClick(event, 0, '/user/profile')
                                                }
                                            >
                                                <ListItemIcon>
                                                    <IconSettings stroke={1.5} size="20px" />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="body2">
                                                            Account Settings
                                                        </Typography>
                                                    }
                                                />
                                            </ListItemButton>
                                            <ListItemButton
                                                sx={{ borderRadius: `${borderRadius}px` }}
                                                selected={selectedIndex === 2}
                                                onClick={() => {
                                                    handleLogout(false)
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <IconLogout stroke={1.5} size="20px" />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="body2">
                                                            <FormattedMessage id="logout" />
                                                        </Typography>
                                                    }
                                                />
                                            </ListItemButton>
                                            <ListItemButton
                                                sx={{ borderRadius: `${borderRadius}px` }}
                                                selected={selectedIndex === 3}
                                                onClick={() => handleLogout(true)}>
                                                <ListItemIcon>
                                                    <IconLogout stroke={1.5} size="20px" />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="body2">
                                                            Logout From All Devices
                                                        </Typography>
                                                    }
                                                />
                                            </ListItemButton>
                                        </List>
                                    </Box>
                                </PerfectScrollbar>
                            </MainCard>
                        )}
                    </Paper>
                </ClickAwayListener>
            </Popper>
            <Dialog
                open={openPayoutDialog}
                onClose={(event: any, reason: string) => {
                    if (reason && reason == "backdropClick" && "escapeKeyDown") return;
                    setOpenPayoutDialog(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ p: 3 }}>
                {openPayoutDialog && (
                    <>
                        <DialogTitle id="alert-dialog-title">{'Do you accept this amount and want to create payout against transactions?'}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {'If you confirm once then you will be not able to change data in future. so please check and confirm data.'}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ pr: 2.5 }}>
                            <Button
                                size='small'
                                onClick={(event: any) => {
                                    //if (reason && reason == "backdropClick" && "escapeKeyDown") return;
                                    setOpenPayoutDialog(false);
                                }}
                                variant='outlined'
                                color="error"
                            >
                                {'Cancel'}
                            </Button>
                            <Button variant="contained" size="small" color='secondary' onClick={() => {
                                handleCreatePayoutCurrent(user?.id);
                            }} autoFocus>
                                {'Agree'}
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    );
};

export default ProfileSection;
