import { memo, useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';

// project imports
import menuItem from 'menu-items';
import NavGroup from './NavGroup';
import useConfig from 'hooks/useConfig';
//import { Menu } from 'menu-items/widget';
import userAuth from 'hooks/useAuth';

import LAYOUT_CONST from 'constant';
import { HORIZONTAL_MAX_ITEM } from 'config';

// types
import { NavItemType } from 'types';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = ({ menu }: { menu: any }) => {
    const theme = useTheme();
    const { layout } = useConfig();
    const currentUser: any = userAuth();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const [menuItemsState, setMenuItemState] = useState<any>();

    // last menu-item to show in horizontal menu bar
    const lastItem = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd ? HORIZONTAL_MAX_ITEM : null;

    useEffect(() => {
        if (menu) {
            if (currentUser?.user?.type === 'user') {
                const data = { ...menu }
                setMenuItemState(data);
            } else {
                setMenuItemState(menu);
            }
        }
    }, [currentUser?.user, menu]);

    let lastItemIndex = menuItem.items.length - 1;
    let remItems: NavItemType[] = [];
    let lastItemId: string;

    if (lastItem && lastItem < menuItem.items.length) {
        lastItemId = menuItem.items[lastItem - 1].id!;
        lastItemIndex = lastItem - 1;
        remItems = menuItem.items.slice(lastItem - 1, menuItem.items.length).map((item) => ({
            title: item.title,
            elements: item.children
        }));
    }

    const navItems = menuItemsState?.items?.map((item: any) => {
        switch (item?.id) {
            case 'partnerMenu':
            case 'admin':
            case 'dashboard':
                if ((currentUser?.user?.type === 'admin' && item?.id === 'admin') || (currentUser?.user?.type === 'user' && item?.id === 'partnerMenu') || item?.id === 'dashboard') {
                    switch (item.type) {
                        case 'group':
                            return <NavGroup key={item.id} item={item} lastItem={lastItem!} remItems={remItems} lastItemId={lastItemId} />;
                        default:
                            return (
                                <Typography key={item.id} variant="h6" color="error" align="center">
                                    Menu Items Error
                                </Typography>
                            );
                    }
                }
                break;
            default:
                break;
        }
    });

    return <>{navItems}</>;
};

export default memo(MenuList);
