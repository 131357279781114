
import { NavItemType } from 'types';

// assets
import {
    IconApps, IconUserCheck, IconBasket, IconMessages, IconLayoutKanban, IconMail, IconCalendar, IconNfc,
    IconBuildingCommunity, IconBuildingBank, IconClipboardList,
    IconBook, IconGizmo, IconUserPlus, IconUsers, IconLanguage, IconClipboard, IconCategory, IconCategory2,
    IconCategoryMinus
} from '@tabler/icons-react';

// constant
const icons = {
    IconApps,
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconBuildingCommunity,
    IconBuildingBank,
    IconClipboardList,
    IconBook,
    IconCategory, IconCategory2,
    IconGizmo,
    IconUserPlus, IconCategoryMinus,
    IconUsers,
    IconLanguage, IconClipboard
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const application: NavItemType = {
    id: 'partnerMenu',
    title: 'App',
    icon: icons.IconApps,
    type: 'group',
    children: [
        {
            id: 'cases',
            breadcrumbs: false,
            title: 'Cases List',
            type: 'item',
            url: '/cases-list',
            icon: icons.IconBook,
        },
        {
            id: 'view',
            breadcrumbs: false,
            title: 'Payout List',
            type: 'item',
            url: '/payout-manager/view',
            icon: icons.IconBook,
        },
    ]
};

export default application;





