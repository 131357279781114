import { useRoutes } from 'react-router-dom';

import AdminLoginRoutes from './AdminLoginRoutes';
import AdminRoutes from './AdminRoutes';
import WebRoutes from './WebRoutes';
import PartnerLoginRoutes from './PartnerLoginRoutes';
import PartnerRoutes from './PartnerRoutes';


export default function ThemeRoutes() {
    const location = window.location.host;
    const pathArr = location.replace(/^\/|\/$/g, '').split('.').slice();

    let baseRoutes = [];
    if (pathArr.includes('partner')) {
        baseRoutes.push(PartnerLoginRoutes, PartnerRoutes);
    } else if (pathArr.includes('app') ) {
        baseRoutes.push(AdminLoginRoutes, AdminRoutes);
    } else {
        baseRoutes.push(WebRoutes);
    }
    return useRoutes(baseRoutes);
}
