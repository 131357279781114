import { createSlice } from '@reduxjs/toolkit';

const apiSettingsSlice = createSlice({
  name: 'apiSettings',
  initialState: {
    smtp: {},
    products: {},
    gmailOauth: {},
    translate: {},
    mailContent: {},
    otpCredential: {},
    contact: {},
    categoryOfAccount: {},
    notificationStatics: {},
    contactPage: {},
    disclaimerPage: {},
    privacyPolicyPage: {},
    refundPolicyPage: {},
    termsConditionsPage: {},
    aboutPage: {},
    servicePage: {},
    transactions: {},
    footerPage: {},
    images: {},
    addUpdateProductTypeSuccess: false,
    loadImageSuccess: false,
  },
  reducers: {
    fetchSmtpSettings(state, action) { 
      state.smtp = action.payload;
    },
    fetchProductsSettings(state, action) { 
      state.products = action.payload;
    },
    fetchGmailOauthSettings(state, action) { 
      state.gmailOauth = action.payload;
    },
    fetchImagesOfStaticsSettings(state, action) { 
      state.images = action.payload;
    },
    fetchContactPageSettings(state, action) { 
      state.contactPage = action.payload;
    },
    fetchAboutPageSettings(state, action) { 
      state.aboutPage = action.payload;
    },
    fetchServicePageSettings(state, action) { 
      state.servicePage = action.payload;
    },
    fetchFooterPageSettings(state, action) { 
      state.footerPage = action.payload;
    },
    fetchDisclaimerPageSettings(state, action) { 
      state.disclaimerPage = action.payload;
    },
    fetchPrivacyPolicyPageSettings(state, action) { 
      state.privacyPolicyPage = action.payload;
    },
    fetchRefundPolicyPageSettings(state, action) { 
      state.refundPolicyPage = action.payload;
    },
    fetchTermsConditionsPageSettings(state, action) { 
      state.termsConditionsPage = action.payload;
    },
    fetchTranslateSettings(state, action) { 
      state.translate = action.payload;
    },
    fetchMailContent(state, action) { 
      state.mailContent = action.payload;
    },
    fetchOtpCredential(state, action) { 
      state.otpCredential = action.payload;
    },
    fetchCategoryOfAccount(state, action) { 
      state.categoryOfAccount = action.payload;
    },
    fetchContactSettings(state, action) { 
      state.contact = action.payload;
    },
    fetchNotificationStatics(state, action) { 
      state.notificationStatics = action.payload;
    },
    fetchTransactionsSettings(state, action) { 
      state.transactions = action.payload;
    },
    addUpdateProductTypeSuccess(state, action) { 
      state.addUpdateProductTypeSuccess = action.payload;
    },
    loadImageSuccessAction(state, action) { 
      state.loadImageSuccess = action.payload;
    }
  },
});

export const {fetchNotificationStatics, fetchProductsSettings, fetchTransactionsSettings, fetchGmailOauthSettings, fetchDisclaimerPageSettings, fetchImagesOfStaticsSettings,
  fetchPrivacyPolicyPageSettings, fetchRefundPolicyPageSettings, fetchTermsConditionsPageSettings,
   fetchContactPageSettings, fetchServicePageSettings, fetchFooterPageSettings, loadImageSuccessAction,
    fetchAboutPageSettings, fetchContactSettings, fetchCategoryOfAccount, fetchOtpCredential, fetchSmtpSettings, fetchTranslateSettings, fetchMailContent, addUpdateProductTypeSuccess} = apiSettingsSlice.actions;

export default apiSettingsSlice.reducer;