import { createSlice } from '@reduxjs/toolkit';

const mailTemplateSlice = createSlice({
  name: 'mailTemplates',
  initialState: {
    templates: [],
    notifications: [],
    success: false,
    editTemplate: null,
    templatesCount: null,
    notificationsCount: null,
    editNotificationsTemplate: null,
    successNotifications: false,
  },
  reducers: {
    fetchTemplates(state, action) {
      state.templates = action.payload;
    },
    editTemplate(state, action) {
      state.editTemplate = action.payload;
    },
    setTemplateCount(state, action) {
      state.templatesCount = action.payload ? action.payload : 0;
    },
    setTemplateSuccess(state, action) {
      state.success = action.payload;
    },
    fetchNotificationsTemplates(state, action) {
      state.notifications = action.payload;
    },
    editNotificationsTemplate(state, action) {
      state.editNotificationsTemplate = action.payload;
    },
    setNotificationsTemplateCount(state, action) {
      state.notificationsCount = action.payload ? action.payload : 0;
    },
    setNotificationsTemplateSuccess(state, action) {
      state.successNotifications = action.payload;
    }
  },
});

export const {
  fetchTemplates, fetchNotificationsTemplates, editNotificationsTemplate,
  setNotificationsTemplateCount, setNotificationsTemplateSuccess,
  setTemplateCount,
  setTemplateSuccess, editTemplate
} = mailTemplateSlice.actions;

export default mailTemplateSlice.reducer;
