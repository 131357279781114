import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';


const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const DashboardAnalytics = Loadable(lazy(() => import('views/dashboard/Analytics')));

const Users = Loadable(lazy(() => import('views/admin/users')));
const AddUser = Loadable(lazy(() => import('views/admin/users/AddUser')));
const EditUser = Loadable(lazy(() => import('views/admin/users/EditUser')));
const Profile = Loadable(lazy(() => import('views/admin/users/profile')));

const RoleAndPermissions = Loadable(lazy(() => import('views/admin/roles')));
const AddRole = Loadable(lazy(() => import('views/admin/roles/AddRole')));
const EditRole = Loadable(lazy(() => import('views/admin/roles/EditRole')));
const MaintenanceError = Loadable(lazy(() => import('views/pages/maintenance/Error')));

const AddLang = Loadable(lazy(() => import('views/admin/lang/AddLang')));
const EditLangs = Loadable(lazy(() => import('views/admin/lang/EditLangs')));
const LangList = Loadable(lazy(() => import('views/admin/lang')));
    
const AddPage = Loadable(lazy(() => import('views/admin/staticPages/AddArticles')));
const EditPage = Loadable(lazy(() => import('views/admin/staticPages/AddArticles')));
const PagesList = Loadable(lazy(() => import('views/admin/staticPages')));

const HomePage = Loadable(lazy(() => import('views/admin/staticPages/home/HomePage')));
const ContactPage = Loadable(lazy(() => import('views/admin/staticPages/contact')));
const PrivacyPolicy = Loadable(lazy(() => import('views/admin/staticPages/privacyPolicy')));
const Disclaimer = Loadable(lazy(() => import('views/admin/staticPages/disclaimer')));
const RefundPolicy = Loadable(lazy(() => import('views/admin/staticPages/refundPolicy')));
const TermsConditions = Loadable(lazy(() => import('views/admin/staticPages/termsConditions')));
const ContactPageSettings = Loadable(lazy(() => import('views/admin/staticPages/contact/ContactSettings')));
const ServicePageSettings = Loadable(lazy(() => import('views/admin/staticPages/services/ServiceSettings')));
const AboutPageSettings = Loadable(lazy(() => import('views/admin/staticPages/about/AboutSettings')));
const FooterPageSettings = Loadable(lazy(() => import('views/admin/staticPages/footer/FooterSettings')));
const ContactInquiry = Loadable(lazy(() => import('views/admin/staticPages/contact/ContactInquiry')));
const CasePage = Loadable(lazy(() => import('views/admin/staticPages/case/CasePage')));
const ImagesPage = Loadable(lazy(() => import('views/admin/staticPages/images/ImagesPage')));

const Complaint = Loadable(lazy(() => import('views/admin/complaint/index')));
const Payments = Loadable(lazy(() => import('views/admin/complaint/Payment')));
const PaymentsTransactions = Loadable(lazy(() => import('views/admin/complaint/ViewTransactions')));
const PaymentsTransactionsSettings = Loadable(lazy(() => import('views/admin/complaint/TransactionsSettings')));
const ViewCaseDetails = Loadable(lazy(() => import('views/admin/complaint/tabs/ComplaintMain')));
const PartnersCases = Loadable(lazy(() => import('views/admin/partners')));

const AddSubscriptions = Loadable(lazy(() => import('views/admin/paymentSubscription/AddTokenCard')));
const ViewSubscriptions = Loadable(lazy(() => import('views/admin/paymentSubscription')));
const PromoCodes = Loadable(lazy(() => import('views/admin/paymentSubscription/PromoCodes')));
const AddUpdatePromoCode = Loadable(lazy(() => import('views/admin/paymentSubscription/AddUpdatePromoCode')));

const SubProduct = Loadable(lazy(() => import('views/admin/product/subProducts')));
const AddSubProduct = Loadable(lazy(() => import('views/admin/product/subProducts/AddProduct')));
const EditSubProduct = Loadable(lazy(() => import('views/admin/product/subProducts/EditProduct')));
const EditSubProductLangs = Loadable(lazy(() => import('views/admin/product/subProducts/langs')));
const ViewStepsList = Loadable(lazy(() => import('views/admin/product/flow/steps')));
const ViewFlowList = Loadable(lazy(() => import('views/admin/product/flow')));

const Product = Loadable(lazy(() => import('views/admin/product/product')));
const AddProduct = Loadable(lazy(() => import('views/admin/product/product/AddProductTypes')));
const EditProduct = Loadable(lazy(() => import('views/admin/product/product/EditProductTypes')));

const ProductTypes = Loadable(lazy(() => import('views/admin/product/productTypes')));
const AddProductTypes = Loadable(lazy(() => import('views/admin/product/productTypes/AddProductTypes')));
const EditProductTypes = Loadable(lazy(() => import('views/admin/product/productTypes/EditProductTypes')));

const AddUsesTerms = Loadable(lazy(() => import('views/admin/mailTemplates/AddMailTemplate')));
const ViewUsesTerms = Loadable(lazy(() => import('views/admin/mailTemplates/')));
const EditMailTemplate = Loadable(lazy(() => import('views/admin/mailTemplates/EditMailTemplate')));
const StaticContent = Loadable(lazy(() => import('views/admin/mailTemplates/StaticsOfMail')));


const Keywords = Loadable(lazy(() => import('views/admin/keywordsSetting')));
const AddKeyword = Loadable(lazy(() => import('views/admin/keywordsSetting/AddKeyword')));
const EditKeyword = Loadable(lazy(() => import('views/admin/keywordsSetting/EditKeyword')));

const CustomFields = Loadable(lazy(() => import('views/admin/product/customFields')));
const Questions = Loadable(lazy(() => import('views/admin/product/questions')));
const CustomFieldsLanguages = Loadable(lazy(() => import('views/admin/product/customFields/langs')));
const QuestionsLanguages = Loadable(lazy(() => import('views/admin/product/questions/langs')));


const FlowBuilders = Loadable(lazy(() => import('views/admin/product/flowBuilder')));
const AddFlowBuilder = Loadable(lazy(() => import('views/admin/product/flowBuilder/AddFlowBuilder')));
const EditFlowBuilder = Loadable(lazy(() => import('views/admin/product/flowBuilder/EditFlowBuilder')));
const EditFlowLanguages = Loadable(lazy(() => import('views/admin/product/flow/steps/langs')));


const Bank = Loadable(lazy(() => import('views/admin/bank')));
const AddBank = Loadable(lazy(() => import('views/admin/bank/AddBank')));
const EditBank = Loadable(lazy(() => import('views/admin/bank/AddBank')));

const ExportTranslations = Loadable(lazy(() => import('views/admin/export/ExportTranslations')));
const Translate = Loadable(lazy(() => import('views/admin/export/Translate')));

const SmtpSettings = Loadable(lazy(() => import('views/admin/apiSettings/google/Smtp')));
const TranslateSettings = Loadable(lazy(() => import('views/admin/apiSettings/google/Translate')));
const ObligrSettings = Loadable(lazy(() => import('views/admin/apiSettings/obligr/ObligrSettings')));
const ZoopSettings = Loadable(lazy(() => import('views/admin/apiSettings/zoop/Zoop')));
const GmailOauth = Loadable(lazy(() => import('views/admin/apiSettings/google/GmailOauth')));
const TestOauth = Loadable(lazy(() => import('views/admin/apiSettings/google/Test')));


// const AddContact = Loadable(lazy(() => import('views/admin/contact/AddContact')));
const AddContactBunch = Loadable(lazy(() => import('views/admin/contact/AddContactBunch')));
const ContactBunchList = Loadable(lazy(() => import('views/admin/contact/ContactBunchList')));
// const Contact = Loadable(lazy(() => import('views/admin/contact')));
const ContactSettings = Loadable(lazy(() => import('views/admin/contact/ContactSettings')));
const ProductSettings = Loadable(lazy(() => import('views/admin/product/product/ProductSettings')));
const ProductIcons = Loadable(lazy(() => import('views/admin/product/product/images/ImagesPage')));

const AddNotifications = Loadable(lazy(() => import('views/admin/notifications/AddNotifications')));
const EditNotifications = Loadable(lazy(() => import('views/admin/notifications/EditNotifications')));
const Notifications = Loadable(lazy(() => import('views/admin/notifications')));
const NotificationsSettings = Loadable(lazy(() => import('views/admin/notifications/NotificationsSettings')));
const SystemNotifications = Loadable(lazy(() => import('views/admin/notifications/SystemNotifications')));
const CreateNotifications = Loadable(lazy(() => import('views/admin/notifications/notifyBunch/AddNotifications')));
const NotificationsBunch = Loadable(lazy(() => import('views/admin/notifications/notifyBunch')));

const AddAccount = Loadable(lazy(() => import('views/admin/accounts/credentials/AddAccount')));
const AccountsList = Loadable(lazy(() => import('views/admin/accounts/credentials')));
const CategoryOfAccount = Loadable(lazy(() => import('views/admin/accounts/category/Category')));

const AddCompany = Loadable(lazy(() => import('views/admin/accounts/companies/AddCompany')));
const CompaniesList = Loadable(lazy(() => import('views/admin/accounts/companies')));

const AddInvoice = Loadable(lazy(() => import('views/admin/accounts/invoices/AddInvoice')));
const InvoicesList = Loadable(lazy(() => import('views/admin/accounts/invoices')));
const FileViewer = Loadable(lazy(() => import('views/admin/accounts/invoices/FileViewer')));


const MisPayoutView = Loadable(lazy(() => import('views/admin/payoutChecker/ChannelTransactionsPayout')));
const MisPayoutViewById = Loadable(lazy(() => import('views/admin/payoutChecker/ViewPayout')));

const ThirdPartyHunter = Loadable(lazy(() => import('views/admin/thirdParty')));
const SearchDomain = Loadable(lazy(() => import('views/admin/thirdParty/SearchDomain')));
const FooterLink = Loadable(lazy(() => import('views/admin/menus/MainMenu')));
const Posts = Loadable(lazy(() => import('views/admin/pagesDynamic')));
const AddPost = Loadable(lazy(() => import('views/admin/pagesDynamic/AddNewPage')));
const EditPost = Loadable(lazy(() => import('views/admin/pagesDynamic/AddNewPage')));


// ==============================|| MAIN ROUTING ||============================== //

const AdminRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard',
            element: <DashboardDefault />,           
        },
        {
            path: '/dashboard/analytics',
            element: <DashboardAnalytics />
        },
        {
            path: '/dynamic/pages/pages-list',
            element: <Posts/>
        },
        {
            path: '/dynamic/pages/create-page',
            element: <AddPost/>
        },
        {
            path: '/dynamic/pages/edit-page/:id',
            element: <EditPost/>
        },
        {
            path: '/payout-manager/view/:id',
            element: <MisPayoutViewById />
        },
        {
            path: '/partner/payout-list',
            element: <MisPayoutView />
        },
        {
            path: '/lang',
            element: <LangList/>
        },
        {
            path: '/lang/add',
            element: <AddLang/>
        },
        {
            path: '/lang/edit/:id',
            element: <EditLangs/>
        },
        {
            path: '/bank/list-bank',
            element: <Bank />
        },
        {
            path: '/bank/add-bank',
            element: <AddBank />
        },
        {
            path: '/bank/edit-bank/:id',
            element: <EditBank />
        },
        {
            path: '/pages',
            element: <PagesList/>
        },
        {
            path: '/pages/add',
            element: <AddPage/>
        },
        {
            path: '/pages/static/home',
            element: <HomePage/>
        },
        {
            path: '/pages/static/footer-page-settings',
            element: <FooterPageSettings/>
        },
        {
            path: '/pages/static/menus',
            element: <FooterLink/>
        },
        {
            path: '/pages/static/contact-page',
            element: <ContactPage/>
        },
        {
            path: '/pages/static/service-page-settings',
            element: <ServicePageSettings/>
        },
        {
            path: '/pages/static/about-page-settings',
            element: <AboutPageSettings/>
        },
        {
            path: '/pages/static/privacy-policy',
            element: <PrivacyPolicy/>
        },
        {
            path: '/pages/static/disclaimer',
            element: <Disclaimer/>
        },
        {
            path: '/pages/static/refund-policy',
            element: <RefundPolicy/>
        },
        {
            path: '/pages/static/terms-conditions',
            element: <TermsConditions/>
        },
        {
            path: '/pages/static/contact-page-settings',
            element: <ContactPageSettings/>
        },
        {
            path: '/pages/static/contact-page-inquiry',
            element: <ContactInquiry/>
        },
        {
            path: '/pages/static/case',
            element: <CasePage/>
        },
        {
            path: '/pages/static/images',
            element: <ImagesPage/>
        },
        {
            path: '/pages/edit/:id',
            element: <EditPage/>
        },
        {
            path: '/mail-templates/list',
            element: <ViewUsesTerms/>
        },
        {
            path: '/mail-templates/create',
            element: <AddUsesTerms />
        },
        {
            path: '/contact/contact-setting',
            element: <ContactSettings/>
        },
        {
            path: '/product/product-setting',
            element: <ProductSettings/>
        },
        {
            path: '/product/product-icons',
            element: <ProductIcons/>
        },
        {
            path: '/contact/create-contact-bunch',
            element: <AddContactBunch />
        },
        {
            path: '/contact/edit-contact-bunch/:id',
            element: <AddContactBunch />
        },
        {
            path: '/contact/contact-list-bunch',
            element: <ContactBunchList />
        },
        {
            path: '/payment/subscriptions',
            element: <ViewSubscriptions/>
        },
        {
            path: '/payment/create-subscription',
            element: <AddSubscriptions />
        },
        {
            path: '/payment/edit-subscription/:id',
            element: <AddSubscriptions />
        },
        {
            path: '/payment/promo-codes',
            element: <PromoCodes />
        },
        {
            path: '/payment/add-promo-codes',
            element: <AddUpdatePromoCode />
        },
        {
            path: '/payment/edit-promo-codes/:id',
            element: <AddUpdatePromoCode />
        },
        {
            path: '/mail-templates/edit-mail-template/:id',
            element: <EditMailTemplate/>
        },
        {
            path: '/mail-templates/static-content',
            element: <StaticContent/>
        },
        {
            path: '/notifications/add-notification',
            element: <AddNotifications />
        },
        {
            path: '/notifications/edit-notification-template/:id',
            element: <EditNotifications/>
        },
        {
            path: '/notifications/notification-settings',
            element: <NotificationsSettings/>
        },
        {
            path: '/notifications/system-notifications',
            element: <SystemNotifications/>
        },
        {
            path: '/notifications/notification-bunch',
            element: <NotificationsBunch/>
        },
        {
            path: '/notifications/create-notification',
            element: <CreateNotifications/>
        },
        {
            path: '/notifications/edit-notification/:id',
            element: <CreateNotifications/>
        },
        {
            path: '/notifications/notification-list',
            element: <Notifications/>
        },
        {
            path: '/complaint/complaint-list',
            element: <Complaint/>
        },
        {
            path: '/third-party/hunter/domains-list',
            element: <ThirdPartyHunter/>
        },
        {
            path: '/third-party/hunter/search-domains',
            element: <SearchDomain/>
        },
        {
            path: '/transactions/transactions-list',
            element: <Payments/>
        },
        {
            path: '/transactions/transactions-view/:id',
            element: <PaymentsTransactions/>
        },
        {
            path: '/transactions/transactions-settings',
            element: <PaymentsTransactionsSettings/>
        },
        {
            path: '/complaint/cases-list',
            element: <PartnersCases/>
        },
        {
            path: '/complaint/case-view/:id',
            element: <ViewCaseDetails/>
        },
        {
            path: '/product-types/product-types-list',
            element: <ProductTypes />
        },
        {
            path: '/product-types/add-product-types',
            element: <AddProductTypes />
        },
        {
            path: '/product-types/edit-product-types/:id',
            element: <EditProductTypes />
        },
        {
            path: '/sub-product/sub-product-list',
            element: <SubProduct />
        },
        {
            path: '/sub-product/add-sub-product',
            element: <AddSubProduct />
        },
        {
            path: '/sub-product/edit-sub-product/:id',
            element: <EditSubProduct />
        },
        {
            path: '/sub-product/edit-sub-product/langs/:id',
            element: <EditSubProductLangs />
        },
        {
            path: '/flow-builder/list',
            element: <FlowBuilders />
        },
        {
            path: '/flow-builder/add',
            element: <AddFlowBuilder />
        },
        {
            path: '/flow-builder/edit/:id',
            element: <EditFlowBuilder />
        },
        {
            path: '/flow-builder/flow/:id',
            element: <ViewStepsList />
        },
        {
            path: '/flow-builder/flow/view/:id',
            element: <ViewFlowList />
        },
        {
            path: '/flow-builder/custom-fields',
            element: <CustomFields />
        },
        {
            path: '/flow-builder/questions',
            element: <Questions/>
        },
        {
            path: '/flow-builder/questions/language/:id',
            element: <QuestionsLanguages/>
        },
        {
            path: '/flow-builder/flow/step/language/:id',
            element: <EditFlowLanguages/>
        },
        {
            path: '/flow-builder/custom-fields/language/:id',
            element: <CustomFieldsLanguages/>
        },
        {
            path: '/product/product-list',
            element: <Product />
        },
        {
            path: '/product/add-product',
            element: <AddProduct />
        },
        {
            path: '/product/edit-product/:id',
            element: <EditProduct/>
        },
        {
            path: '/keywords/add-keyword',
            element: <AddKeyword/>
        },
        {
            path: '/keywords/keyword-list',
            element: <Keywords/>
        },
        {
            path: '/keywords/edit-keyword/:id',
            element: <EditKeyword/>
        },
        {
            path: '/export/translations',
            element: <ExportTranslations />
        },
        {
            path: '/export/translate',
            element: <Translate />
        },
        {
            path: '/api-settings/google/smtp',
            element: <SmtpSettings />
        },
        {
            path: '/api-settings/google/translate',
            element: <TranslateSettings />
        },
        {
            path: '/api-settings/google/gmail-oauth',
            element: <GmailOauth/>
        },
        {
            path: '/api-settings/google/gmail-oauth/test',
            element: <TestOauth/>
        },
        {
            path: '/api-settings/obligr',
            element: <ObligrSettings />
        },
        {
            path: '/api-settings/zoop',
            element: <ZoopSettings />
        },
        //accounts
        {
            path: '/account/credential/add-credential',
            element: <AddAccount />
        },
        {
            path: '/account/credential/edit-credential/:id',
            element: <AddAccount />
        },
        {
            path: '/account/companies/add-company',
            element: <AddCompany />
        },
        {
            path: '/account/companies/edit-company/:id',
            element: <AddCompany />
        },
        {
            path: '/account/companies/companies-list',
            element: <CompaniesList />
        },
        {
            path: '/account/credential/credential-list',
            element: <AccountsList />
        },
        {
            path: '/account/category',
            element: <CategoryOfAccount />
        },
        {
            path: '/account/invoices/add-invoice',
            element: <AddInvoice />
        },
        {
            path: '/account/invoices/edit-invoice/:id',
            element: <AddInvoice />
        },
        {
            path: '/account/invoices/invoices-list',
            element: <InvoicesList />
        },
        {
            path: '/account/invoices/file-viewer/:fileName',
            element: <FileViewer />
        },
        //end account
        {
            path: '/user/users-list',
            element: <Users />
        },
        {
            path: '/user/add-user',
            element: <AddUser />
        },
        {
            path: '/user/edit-user/:id',
            element: <EditUser />
        },
        {
            path: '/user/profile',
            element: <Profile />
        },
        {
            path: '/user/role/roles-list',
            element: <RoleAndPermissions />
        },
        {
            path: '/user/role',
            element: <RoleAndPermissions />
        },
        {
            path: '/user/role/add-role',
            element: <AddRole/>
        },
        {
            path: '/user/role/edit-role/:id',
            element: <EditRole/>
        },
        {
            path: '/*',
            element: <MaintenanceError/>
        }
    ],

};

export default AdminRoutes;
