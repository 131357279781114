import { createSlice } from '@reduxjs/toolkit';

const contactInquirySlice = createSlice({
  name: 'contactInquiry',
  initialState: {
    inquiries: [],
    contactCount: 0,
    addUpdateContactSuccess: false,
  },
  reducers: {
    fetchContactsInquiry(state, action) { 
      state.inquiries = action.payload;
    },
    fetchContactsInquiryCount(state, action) { 
      state.contactCount = action.payload ? action.payload: 0;
    },
    addUpdateContactsInquirySuccess(state, action) { 
      state.addUpdateContactSuccess = action.payload;
    },
  },
});

export const { fetchContactsInquiry, fetchContactsInquiryCount, addUpdateContactsInquirySuccess} = contactInquirySlice.actions;

export default contactInquirySlice.reducer;