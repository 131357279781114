import { createSlice } from '@reduxjs/toolkit';

const complaintCaseSlice = createSlice({
  name: 'complaintCase',
  initialState: {
    cases: [],
    casesPartners: [],
    payouts: [],
    editPayout: null,
    steps: [],
    editComplaintCase: null,
    casesPartnerCount: null,
    casesCount: null,
    payoutCount: null,
    addUpdateComplaintSuccess: false,
    payoutsTotalAmountWithFilter: null
  },
  reducers: {
    fetchComplaintCases(state, action) { 
      state.cases = action.payload;
    },
    fetchComplaintPayoutEdit(state, action) { 
      state.editPayout = action.payload;
    },
    fetchEditComplaintCase(state, action) { 
      state.editComplaintCase = action.payload;
    },
    fetchStepsCases(state, action) { 
      state.steps = action.payload;
    },
    fetchComplaintPartnersCases(state, action) { 
      state.casesPartners = action.payload;
    },
    fetchComplaintPayout(state, action) { 
      state.payouts = action.payload;
    },
    payoutsTotalAmountWithFilter(state, action) { 
      state.payoutsTotalAmountWithFilter = action.payload;
    },
    fetchComplaintCaseCount(state, action) { 
      state.casesCount = action.payload ? action.payload: 0;
    },
    fetchComplaintPayoutCount(state, action) { 
      state.payoutCount = action.payload ? action.payload: 0;
    },
    fetchComplaintPartnerCount(state, action) { 
      state.casesPartnerCount = action.payload ? action.payload: 0;
    },

    addUpdateComplaintSuccess(state, action) { 
      state.addUpdateComplaintSuccess = action.payload;
    },

  },
});

export const {fetchComplaintPayoutEdit, payoutsTotalAmountWithFilter, fetchEditComplaintCase, fetchComplaintPartnerCount, fetchStepsCases, fetchComplaintPartnersCases, fetchComplaintCases, fetchComplaintPayout, fetchComplaintPayoutCount, fetchComplaintCaseCount, addUpdateComplaintSuccess } = complaintCaseSlice.actions;

export default complaintCaseSlice.reducer;