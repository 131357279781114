// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Button, Typography, useMediaQuery } from '@mui/material';

// project imports
import LAYOUT_CONST from 'constant';
import useConfig from 'hooks/useConfig';
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
// import MegaMenuSection from './MegaMenuSection';
import NotificationSection from './NotificationSection';

import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/state/data-slices/menu';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
// assets
import { IconMenu2 } from '@tabler/icons';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { openSnackbar } from 'store/state/data-slices/snackbar';
import { getCurrentUserProfile } from 'store/state/dsaSelector';
import upperCase from 'lodash/upperCase';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const getCurrentUser = useSelector(getCurrentUserProfile);
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const { layout } = useConfig();
    const userType = upperCase(getCurrentUser?.type);
    const inviteCode = getCurrentUser?.inviteCode;

    const handleCopyClick = () => {
        if (!inviteCode) {
            dispatch(
                openSnackbar({
                    open: true,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    message: 'Invite code not found. Please generate code in profile settings.',
                    variant: 'alert',
                    alert: {
                        color: 'error',
                    },
                    close: true,
                }));
                return;
        }
        const url = `${process?.env?.REACT_APP_WEBSITE}?invite=${inviteCode}`;
        navigator.clipboard.writeText(url)
            .then(() => {
                //setIsCopied(true);
                dispatch(
                    openSnackbar({
                        open: true,
                        anchorOrigin: { vertical: 'top', horizontal: 'right' },
                        message: 'Invite Link Copied',
                        variant: 'alert',
                        alert: {
                            color: 'success',
                        },
                        close: false,
                        
                    }));
            })
            .catch((error) => {
                console.error('Failed to copy text:', error);
            });
    };


    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                {(layout === LAYOUT_CONST.VERTICAL_LAYOUT || (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd)) && (
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            overflow: 'hidden',
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                            color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                                color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
                            }
                        }}
                        onClick={() => dispatch(openDrawer(!drawerOpen))}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="20px" />
                    </Avatar>
                )}
            </Box>

            {/* header search */}
            <SearchSection />
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {/* mega-menu */}
            {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <MegaMenuSection />
            </Box> */}

            {/* live customization & localization */}
            {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <LocalizationSection />
            </Box> */}

            {/* notification & profile */}
            <NotificationSection />
            <Box sx={{ mr: '20px', textAlign: 'center' }}>
                <AnimateButton type="scale" scale={{
                    hover: 1.1,
                    tap: 0.9
                }}>
                    <Button color="secondary" size="small" variant='outlined' onClick={() => {
                        handleCopyClick();
                    }}>
                        <PersonAddAltIcon sx={{ fontSize: '20px', mr: '5px' }} /> {' '}
                        <Typography variant="subtitle1" color="secondary">Invite</Typography>
                    </Button>
                </AnimateButton>
            </Box>
            <ProfileSection />

            {/* mobile header */}
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box>
        </>
    );
};

export default Header;
