import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/admin/pages/auth/AdminLogin')));
const AuthRegister = Loadable(lazy(() => import('views/admin/pages/auth/Register1')));
const MaintenanceError = Loadable(lazy(() => import('views/pages/maintenance/Error')));

// ==============================|| AUTH ROUTING ||============================== //

const PartnerLoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/',
            element: <AuthLogin />
        },
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/',
            element: <MaintenanceError/>
        },
        {
            path: '/register',
            element: <AuthRegister/>
        },
        {
            path: '/*',
            element: <MaintenanceError/>
        }
    ]
};

export default PartnerLoginRoutes;
